import * as Babylon from 'babylonjs';
import 'babylonjs-loaders';
import * as GUI from 'babylonjs-gui';

let initialized = false;
let fileNames = ['grosseWerkstuecke', 'asymmetrischeWerkstruecke', 'mehrfachSpannung', 'rundeWerkstuecke', 'flacheWerkstuecke'];
let textBlocks = [
  'Spannen von \ngroßen Werkstücken',
  'Spannen von \nasymmetrischen Werkstücken',
  'Mehrfachspannung \nvon Werkstücken',
  'Spannung von \nrunden Werkstücken',
  'Spannen von Platten \nund flachen Werkstücken'
];

let canvas = document.getElementById('webgl-canvas');
let engine = new Babylon.Engine(canvas, true, null, true);
let loadingScreen = new CustomLoadingScreen('loading-screen');
engine.loadingScreen = loadingScreen;

let framerate = 25;
let framerateMultiplier = 10;
let startTime;
let loadingAnimationMinTime = 10000; // minimal time the loading animation should last in ms

let situationMeshes = [];
let buttons = [];

let allTotal = 0;
let allLoaded = 0;
let allCount = 0;
let allPercentageLoaded = 0;
let loaded = [0, 0, 0, 0, 0];

function initBabylonCanvas() {
  startTime = new Date();
  engine.displayLoadingUI();
  // Babylon.SceneLoader.Load('js/assets/v04/base/', 'Base.babylon', engine, function(scene) {
  Babylon.SceneLoader.Load('js/assets/ultra/', 'BaseScene.babylon', engine, function(scene) {
    // Scene settings
    scene.clearColor = new Babylon.Color4(0, 0, 0, 0);
    scene.stopAllAnimations();

    // Create some lights and shadows
    // let hemisLight = new Babylon.HemisphericLight('Hemispheric Light', new Babylon.Vector3(0, 0, 0), scene);
    let mainLight = new Babylon.DirectionalLight('Main Light', new Babylon.Vector3(-0.5, -1, -0.5), scene);
    mainLight.autoUpdateExtends = false;
    let shadowGenerator = new Babylon.ShadowGenerator(1536, mainLight);
    shadowGenerator.bias = 0.00000000001;
    shadowGenerator.normalBias = 0.000000000005;
    shadowGenerator.depthScale = 25;
    shadowGenerator.useContactHardeningShadow = true;
    shadowGenerator.contactHardeningLightSizeUVRatio = 0.000000000005;
    shadowGenerator.filteringQuality = Babylon.ShadowGenerator.QUALITY_LOW;
    shadowGenerator.forceBackFacesOnly = true;
    shadowGenerator.forceCompilation();
    let reflectionTexture = new Babylon.HDRCubeTexture('js/assets/hdr_textures/industrial_pipe_and_valve_02_1k_bw.hdr', scene, 64, false, false, false, false);
    scene.environmentTexture = reflectionTexture;
    scene.meshes.forEach((mesh) => {
      shadowGenerator.removeShadowCaster(mesh);
      mesh.receiveShadows = false;
    });

    // Create Camera and Camera Settings
    let camera = new Babylon.ArcRotateCamera('Camera', Math.PI / 2, Math.PI / 2, 0.5, new Babylon.Vector3(0, 0.0635, 0), scene);
    camera.maxZ = 1000; // Set clipping planes
    camera.minZ = -1000;
    camera.attachControl(canvas, true);
    camera.allowUpsideDown = false; // Do not allow view from beneath
    camera.panningSensibility = 0; // prevent panning
    camera.lowerRadiusLimit = 0.5;
    camera.upperRadiusLimit = 0.5;
    camera.inertialBetaOffset = 1000; // Inertia settings
    camera.inertialAlphaOffset = 1000; // Inertia settings
    camera.lowerBetaLimit = 0.75; // Limit down angle
    camera.upperBetaLimit = 1.5; // Limit up angle
    camera.inertia = 0.775; // Apply inertia
    // camera.inputs.remove(camera.inputs.attached.keyboard); // no keyboard inputs
    camera.inputs.remove(camera.inputs.attached.mousewheel); // no mousewheel inputs
    scene.activeCamera.attachControl(canvas, true);

    // Create GUI Object
    let gui = GUI.AdvancedDynamicTexture.CreateFullscreenUI(
      'GUI',
      true,
      scene,
    );
    gui._shouldBlockPointer = false;
    let style = gui.createStyle();

    // Import base plates
    Babylon.SceneLoader.ImportMesh('', 'js/assets/v04/base/', 'Base.babylon', scene);

    fileNames.forEach((fileName) => {
      let totaled = false;
      let fileIndex = fileNames.indexOf(fileName);
      Babylon.SceneLoader.ImportMesh('', 'js/assets/v04/' + fileIndex + '/', fileName + '.babylon', scene, function(importedMeshes) {
        let animationGroups = [];
        let rotationGroup = new Babylon.AnimationGroup('IdleRotation');
        let importedNames = [];
        importedMeshes.forEach((mesh) => {
          situationMeshes.push(mesh);
          // Disable meshes that are not displayed right at the start
          if(fileIndex !== 0) {
            mesh.setEnabled(false);
          } else {
            // Add mesh to the shadow generator
            shadowGenerator.addShadowCaster(mesh);
            mesh.receiveShadows = true;
          }
          mesh.name = fileName + '_' + mesh.name;
          importedNames.push(mesh.name);
          if(mesh.material) {
            mesh.material.backFaceCulling = true;
          }

          // Create an idle animation
          let baseRotation = new Babylon.Vector3(0, 0, 0);
          let negBaseRotation = new Babylon.Vector3(0, 0, 0);
          if(mesh.getAnimationByName('rotation animation')) {
            // let index = 0;
            // let maxValue = 0;
            let keys = mesh.getAnimationByName('rotation animation').getKeys();
            // keys.forEach((key) => {
            //   let _maxValue = maxValue;
            //   maxValue = key.value.x > maxValue ? key.value.x : key.value.y > maxValue ? key.value.y : key.value.z > maxValue ? key.value.z : maxValue;
            //   index = _maxValue !== maxValue ? keys.indexOf(key) : index;
            // });
            let value = keys[0].value;
            baseRotation = new Babylon.Vector3(value.x, value.y, value.z);
            negBaseRotation = new Babylon.Vector3(-baseRotation.x, -baseRotation.y, -baseRotation.z);
          }
          if(baseRotation.x === 0 && baseRotation.y === 0 && baseRotation.z === 0) {
            baseRotation = new Babylon.Vector3(randomNumber(-0.5, 0.5),randomNumber(-0.5, 0.5), randomNumber(-0.5, 0.5));
            negBaseRotation = new Babylon.Vector3(-baseRotation.x, -baseRotation.y, -baseRotation.z);
          }
          let _framerateMultiplier = randomNumber(5, 10);
          let rotationAnimation = new Babylon.Animation(mesh.name + '_idle_rotation', 'rotation', framerate, Babylon.Animation.ANIMATIONTYPE_VECTOR3, Babylon.Animation.ANIMATIONLOOPMODE_RELATIVE);
          let rotationKeys = [];
          rotationKeys.push({
            frame: 0,
            value: baseRotation,
          });
          rotationKeys.push({
            frame: framerate * _framerateMultiplier / 2,
            value: negBaseRotation,
          });
          rotationKeys.push({
            frame: framerate * _framerateMultiplier,
            value: baseRotation,
          });
          rotationAnimation.setKeys(rotationKeys);
          rotationGroup.addTargetedAnimation(rotationAnimation, mesh);

          // Build animation groups
          let animationGroup = new Babylon.AnimationGroup(mesh.name + '_animation', scene);
          mesh.animations.forEach((animation) => {
            animationGroup.addTargetedAnimation(animation, mesh);
          });
          animationGroup.normalize(0, 120);
          animationGroups.push(animationGroup);
        });
        rotationGroup.normalize(0, 120);
        // rotationGroup.start(true, 0.25, 0, 120, true);

        // Create a corresponding button to control the animation
        let button = GUI.Button.CreatePulsatingButton(
          fileName + '_button',
          textBlocks[fileIndex],
          'js/assets/gui/button_inner.png',
          'js/assets/gui/button_pulse.png',
        );
        let x = fileIndex - 2;
        let y = Math.pow(x, 2);
        button.width = '325px';
        button.height = '275px';
        button._children[button._children.length - 1].paddingLeftInPixels = parseInt(button.width)/3;
        // let xOffset = x < 0 ? (fileIndex * 100) + 100 : 0;
        let xOffset = x === -1 ? Math.abs(x) * 100 + 75 : 0;
        xOffset = x === 1 ? 200 : xOffset;
        // button.left = x * 250 - xOffset + 'px';
        button.left = x * 250 + Math.sign(x) * xOffset + 'px';
        let yOffset = y >= 0 ? x * 3 : 0;
        yOffset = y === 0 ? 6 : yOffset;
        button.top = 30 - y * 12 + yOffset + '%';
        button.thickness = 0;
        button.isPointerBlocker = false;
        button.pointerEnterAnimation = () => {
          console.log('now grabbing');
          canvas.style.cursor = 'grabbing';
        };
        button.pointerOutAnimation = () => {
          console.log('now grab');
          canvas.style.cursor = 'grab';
        };
        buttons.push(button);

        // Make button pulse
        let pulseAnimationGroup = new Babylon.AnimationGroup(button.name + '_pulse', scene);
        let pulses = 2;
        for(let i = 1; i <= pulses; i++) {
          let frameDelay = i/1.5 * 10;
          let frameEnd = framerate;// * i/2;
          let pulseAnimationScaleX = new Babylon.Animation('pulse', 'scaleX', framerate, Babylon.Animation.ANIMATIONTYPE_FLOAT, Babylon.Animation.ANIMATIONLOOPMODE_CYCLE);
          let pulseFramesX = [];
          pulseFramesX.push({
            frame: 0,
            value: 0.6,
          });
          pulseFramesX.push({
            frame: frameDelay,
            value: 0.6,
          });
          pulseFramesX.push({
            frame: frameEnd,
            value: 1,
          });
          pulseAnimationScaleX.setKeys(pulseFramesX);
          let pulseAnimationScaleY = new Babylon.Animation('pulse', 'scaleY', framerate, Babylon.Animation.ANIMATIONTYPE_FLOAT, Babylon.Animation.ANIMATIONLOOPMODE_CYCLE);
          let pulseFramesY = [];
          pulseFramesY.push({
            frame: 0,
            value: 0.6,
          });
          pulseFramesY.push({
            frame: frameDelay,
            value: 0.6,
          });
          pulseFramesY.push({
            frame: frameEnd,
            value: 1,
          });
          pulseAnimationScaleY.setKeys(pulseFramesY);
          let pulseAnimationOpacity = new Babylon.Animation('pulse', 'alpha', framerate, Babylon.Animation.ANIMATIONTYPE_FLOAT, Babylon.Animation.ANIMATIONLOOPMODE_CYCLE);
          let pulseFramesOpacity = [];
          pulseFramesOpacity.push({
            frame: 0,
            value: 1,
          });
          pulseFramesOpacity.push({
            frame: frameDelay,
            value: 1,
          });
          pulseFramesOpacity.push({
            frame: frameEnd,
            value: 0,
          });
          pulseAnimationOpacity.setKeys(pulseFramesOpacity);
          pulseAnimationGroup.addTargetedAnimation(pulseAnimationScaleX, button._children[i]);
          pulseAnimationGroup.addTargetedAnimation(pulseAnimationScaleY, button._children[i]);
          pulseAnimationGroup.addTargetedAnimation(pulseAnimationOpacity, button._children[i]);
        }
        pulseAnimationGroup.start(true, 0.75, 0, framerate * 3/2, true);

        // Mouseover animation
        let mouseDownGroup = new Babylon.AnimationGroup(button.name + '_mouse_down', scene);
        let mouseDownScaleXAnimation = new Babylon.Animation('scaleX', 'scaleX', framerate, Babylon.Animation.ANIMATIONTYPE_FLOAT, Babylon.Animation.ANIMATIONLOOPMODE_CONSTANT);
        let mouseDownScaleXFrames = [];
        mouseDownScaleXFrames.push({
          frame: 0,
          value: 1,
        });
        mouseDownScaleXFrames.push({
          frame: framerate,
          value: 0.5,
        });
        mouseDownScaleXAnimation.setKeys(mouseDownScaleXFrames);
        let mouseDownScaleYAnimation = new Babylon.Animation('scaleY', 'scaleY', framerate, Babylon.Animation.ANIMATIONTYPE_FLOAT, Babylon.Animation.ANIMATIONLOOPMODE_CONSTANT);
        let mouseDownScaleYFrames = [];
        mouseDownScaleYFrames.push({
          frame: 0,
          value: 1,
        });
        mouseDownScaleYFrames.push({
          frame: framerate,
          value: 0.5,
        });
        mouseDownScaleYAnimation.setKeys(mouseDownScaleYFrames);
        let mouseDownOuterScaleX = new Babylon.Animation('scaleX', 'scaleX', framerate, Babylon.Animation.ANIMATIONTYPE_FLOAT, Babylon.Animation.ANIMATIONLOOPMODE_CONSTANT);
        let mouseDownOuterScaleXFrames = [];
        mouseDownOuterScaleXFrames.push({
          frame: 0,
          value: 0.6,
        });
        mouseDownOuterScaleXFrames.push({
          frame: framerate,
          value: 0,
        });
        mouseDownOuterScaleX.setKeys(mouseDownOuterScaleXFrames);
        let mouseDownOuterScaleY = new Babylon.Animation('scaleY', 'scaleY', framerate, Babylon.Animation.ANIMATIONTYPE_FLOAT, Babylon.Animation.ANIMATIONLOOPMODE_CONSTANT);
        let mouseDownOuterScaleYFrames = [];
        mouseDownOuterScaleYFrames.push({
          frame: 0,
          value: 0.6,
        });
        mouseDownOuterScaleYFrames.push({
          frame: framerate,
          value: 0,
        });
        mouseDownOuterScaleY.setKeys(mouseDownOuterScaleYFrames);
        for(let i = 1; i <= pulses; i++) {
          let pulseAnimationScaleX = new Babylon.Animation('pulse', 'scaleX', framerate, Babylon.Animation.ANIMATIONTYPE_FLOAT, Babylon.Animation.ANIMATIONLOOPMODE_CYCLE);
          let pulseFramesX = [];
          pulseFramesX.push({
            frame: framerate,
            value: 0,
          });
          pulseAnimationScaleX.setKeys(pulseFramesX);
          let pulseAnimationScaleY = new Babylon.Animation('pulse', 'scaleY', framerate, Babylon.Animation.ANIMATIONTYPE_FLOAT, Babylon.Animation.ANIMATIONLOOPMODE_CYCLE);
          let pulseFramesY = [];
          pulseFramesY.push({
            frame: framerate,
            value: 1,
          });
          pulseAnimationScaleY.setKeys(pulseFramesY);
          mouseDownGroup.addTargetedAnimation(pulseAnimationScaleX, button._children[i]);
          mouseDownGroup.addTargetedAnimation(pulseAnimationScaleY, button._children[i]);
        }
        mouseDownGroup.addTargetedAnimation(mouseDownScaleYAnimation, button._children[0]);
        mouseDownGroup.addTargetedAnimation(mouseDownScaleXAnimation, button._children[0]);
        mouseDownGroup.addTargetedAnimation(mouseDownOuterScaleX, button._children[3]);
        mouseDownGroup.addTargetedAnimation(mouseDownOuterScaleY, button._children[3]);

        let mouseUpGroup = new Babylon.AnimationGroup(button.name + '_mouse_up', scene);
        let mouseUpScaleXAnimation = new Babylon.Animation('scaleX', 'scaleX', framerate, Babylon.Animation.ANIMATIONTYPE_FLOAT, Babylon.Animation.ANIMATIONLOOPMODE_CONSTANT);
        let mouseUpScaleXFrames = [];
        mouseUpScaleXFrames.push({
          frame: 0,
          value: 0.5,
        });
        mouseUpScaleXFrames.push({
          frame: framerate,
          value: 1,
        });
        mouseUpScaleXAnimation.setKeys(mouseUpScaleXFrames);
        let mouseUpScaleYAnimation = new Babylon.Animation('scaleY', 'scaleY', framerate, Babylon.Animation.ANIMATIONTYPE_FLOAT, Babylon.Animation.ANIMATIONLOOPMODE_CONSTANT);
        let mouseUpScaleYFrames = [];
        mouseUpScaleYFrames.push({
          frame: 0,
          value: 0.5,
        });
        mouseUpScaleYFrames.push({
          frame: framerate,
          value: 1,
        });
        mouseUpScaleYAnimation.setKeys(mouseUpScaleYFrames);
        let mouseUpOuterScaleX = new Babylon.Animation('scaleX', 'scaleX', framerate, Babylon.Animation.ANIMATIONTYPE_FLOAT, Babylon.Animation.ANIMATIONLOOPMODE_CONSTANT);
        let mouseUpOuterScaleXFrames = [];
        mouseUpOuterScaleXFrames.push({
          frame: 0,
          value: 0,
        });
        mouseUpOuterScaleXFrames.push({
          frame: framerate,
          value: 0.6,
        });
        mouseUpOuterScaleX.setKeys(mouseUpOuterScaleXFrames);
        let mouseUpOuterScaleY = new Babylon.Animation('scaleY', 'scaleY', framerate, Babylon.Animation.ANIMATIONTYPE_FLOAT, Babylon.Animation.ANIMATIONLOOPMODE_CONSTANT);
        let mouseUpOuterScaleYFrames = [];
        mouseUpOuterScaleYFrames.push({
          frame: 0,
          value: 0,
        });
        mouseUpOuterScaleYFrames.push({
          frame: framerate,
          value: 0.6,
        });
        mouseUpOuterScaleY.setKeys(mouseUpOuterScaleYFrames);
        mouseUpGroup.addTargetedAnimation(mouseUpScaleYAnimation, button._children[0]);
        mouseUpGroup.addTargetedAnimation(mouseUpScaleXAnimation, button._children[0]);
        mouseUpGroup.addTargetedAnimation(mouseUpOuterScaleX, button._children[3]);
        mouseUpGroup.addTargetedAnimation(mouseUpOuterScaleY, button._children[3]);

        // Handle button presses
        button.isPointerBlocker = true;
        button.onPointerDownObservable.add(() => {
          buttons.forEach((_button) => {
            if(_button !== button) {
              _button.isVisible = false;
            }
          });
          button._children[button._children.length - 1].isVisible = false;
          button._children[button._children.length - 2].isVisible = false;

          button.isPointerBlocker = false;
          // Stop pulse animation and shrink button
          pulseAnimationGroup.stop();
          mouseDownGroup.start(false, 1.5, 0, framerate, false);
          // Enabled needed meshes, disable others
          situationMeshes.forEach((mesh) => {
            if(mesh.name.includes(fileName)) {
              mesh.setEnabled(true);
              shadowGenerator.addShadowCaster(mesh);
              mesh.receiveShadows = true;
            } else {
              mesh.setEnabled(false);
              shadowGenerator.removeShadowCaster(mesh);
              mesh.receiveShadows = false;
            }
          });
          // Let loop animation finish and speed it up
          // rotationGroup.loopAnimation = false;
          // rotationGroup.speedRatio = 15;
          // Build vice
          // rotationGroup.onAnimationGroupEndObservable.add(() => {
            animationGroups.forEach((animationGroup) => {
              animationGroup.start(false, 3, 60, 0, true);
            });
          // });
          document.querySelector('#info-0' + fileIndex).style.display = 'block';
          window.addEventListener('mousemove', cameraRotate);

          scene.onPointerMove = cameraRotate;

          // Add Listener to go back to default position
          document.addEventListener('click', function() {
            animationGroups.forEach((animationGroup) => {
              animationGroup.stop();
            });
            buttons.forEach((_button) => {
              if(_button !== button) {
                _button.isVisible = true;
              }
            });
            button._children[button._children.length - 1].isVisible = true;
            button._children[button._children.length - 2].isVisible = true;
            document.querySelector('#info-0' + fileIndex).style.display = 'none';

            scene.onPointerMove = null;
            button.isPointerBlocker = true;
            window.removeEventListener('mousemove', cameraRotate);
            // Restart button pulse
            mouseUpGroup.start(false, 1.5, 0, framerate, false);
            pulseAnimationGroup.start(true, 0.75, 0, framerate * 3/2, true);
            // Move vice back to default position
            animationGroups.forEach((animationGroup) => {
              animationGroup.start(false, 3, 0, 60, true);
            });
            // rotationGroup.start(true, 0.25, 0, 120, true);
          }, {once: true});
        });
        gui.addControl(button);
      }, function(event) {
        if(!totaled) {
          totaled = true;
          allTotal += event.total;
        }
        /*if(fileIndex === fileNames.length - 1) {
          let loadedPercent = 0;
          if(event.lengthComputable) {
            loadedPercent = (event.loaded * 100 / event.total).toFixed();
          } else {
            let dlCount = event.loaded / (1024 * 1024);
            loadedPercent = Math.floor(dlCount * 100) / 100;
          }
          // console.log(fileIndex + ': ' + loadedPercent);
          document.querySelector('#loading-percentage').innerHTML = fileIndex + ' ' + loadedPercent + '%';
          if(loadedPercent === 100/!* && fileIndex === fileNames.length *!//!*&& new Date() -
           startTime >
           loadingAnimationMinTime*!/) {
            engine.hideLoadingUI();
          }
        }*/
        // console.log(allLoaded + ' / ' + allTotal);
        // console.log(event);
        allLoaded += event.loaded;
        allCount = allLoaded / (1024 * 1024);
        loaded[fileIndex] = event.loaded;
        allPercentageLoaded = (event.loaded * 100 / event.total).toFixed();// > allPercentageLoaded ? (event.loaded * 100 / event.total).toFixed() : allPercentageLoaded;
        // document.querySelector('#loading-percentage').innerHTML = allPercentageLoaded + '%';
        document.querySelector('#progress-bar .progress').style.width = allPercentageLoaded + '%';
      });
    });

    function cameraRotate(event) {
      // console.log(event);
      camera.alpha += event.movementX * 0.00325;
      camera.beta = Math.max(0.75, Math.min(camera.beta + -event.movementY * 0.0008125, 1.5));
    }

    // scene.debugLayer.show();
    engine.runRenderLoop(function() {
      scene.render();
    });

    window.addEventListener('resize', function () {
      engine.resize();
    });
  });
};

// Observer to initialize babylon app when section is reached and not on initial site load
let canvasObserver = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if(entry.isIntersecting && !initialized) {
      initialized = true;
      initBabylonCanvas();
    }
  })
}, {threshold: 1.0});
canvasObserver.observe(document.querySelector('#webgl-canvas'));

// Pulsing button
GUI.Button.CreatePulsatingButton = function(name, text, image, pulseImage) {
  let result = new GUI.Button(name);

  let iconImage = new GUI.Image(name + '_icon', image);
  iconImage.width = '30px';
  iconImage.height = '30px';
  iconImage.stretch = GUI.Image.STRETCH_UNIFORM;
  iconImage.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
  iconImage.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
  iconImage.top = 25;
  iconImage.left = -35;
  result.addControl(iconImage);

  let iconPulseImage = new GUI.Image(name + '_pulse_icon', pulseImage);
  let iconPulseImage2 = new GUI.Image(name + '_pulse_icon', pulseImage);
  let iconPulseImage3 = new GUI.Image(name + '_pulse_icon', pulseImage);
  iconPulseImage.width = '80px';
  iconPulseImage.height = '80px';
  iconPulseImage2.width = '80px';
  iconPulseImage2.height = '80px'
  iconPulseImage3.width = '80px';
  iconPulseImage3.height = '80px';
  iconPulseImage.scaleX = 0.6;
  iconPulseImage.scaleY = 0.6;
  iconPulseImage2.scaleX = 0.6;
  iconPulseImage2.scaleY = 0.6;
  iconPulseImage3.scaleX = 0.6;
  iconPulseImage3.scaleY = 0.6;
  iconPulseImage.stretch = GUI.Image.STRETCH_NONE;
  iconPulseImage2.stretch = GUI.Image.STRETCH_NONE;
  iconPulseImage3.stretch = GUI.Image.STRETCH_NONE;
  iconPulseImage.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
  iconPulseImage2.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
  iconPulseImage3.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
  iconPulseImage.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
  iconPulseImage2.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
  iconPulseImage3.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
  iconPulseImage.left = -35;
  iconPulseImage2.left = -35;
  iconPulseImage3.left = -35;
  result.addControl(iconPulseImage);
  result.addControl(iconPulseImage2);
  result.addControl(iconPulseImage3);

  let arrowIcon = new GUI.Image(name + '_arrow', 'js/assets/gui/icon_arrow.png');
  arrowIcon.width = '16px';
  arrowIcon.height = '16px';
  arrowIcon.scaleX = 1;
  arrowIcon.scaleY = 1;
  arrowIcon.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
  arrowIcon.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
  arrowIcon.top = -43;
  arrowIcon.left = -75;
  result.addControl(arrowIcon);

  let textBlock = new GUI.TextBlock(name + '_button', text);
  textBlock.textWrapping = true;
  textBlock.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
  textBlock.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
  textBlock.top = 80;
  textBlock.fontSize = '24px';
  textBlock.color = 'white';
  textBlock.fontFamily = 'Encode Sans Compressed Bold';
  textBlock.isHitTestVisible = false;
  result.addControl(textBlock);

  return result;
}

// Custom loading Screen
function CustomLoadingScreen(loadingScreenID) {
  this.loadingScreenID = loadingScreenID;
}

CustomLoadingScreen.prototype.displayLoadingUI = function() {
  document.querySelector('#' + this.loadingScreenID).setAttribute('class', 'active');
}

CustomLoadingScreen.prototype.hideLoadingUI = function() {
  document.querySelector('#' + this.loadingScreenID).style.zIndex = '-1';
  document.querySelector('#' + this.loadingScreenID).style.opacity = '0';
}

// Helper functions
function randomNumber(min, max) {
  return Math.random() * (max - min) + min;
}
